@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffc107;
  --foreground: #fff;
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}
html {
  scroll-behavior: smooth !important;
}
